<template>
  <Dialog v-model:open="dialog.open" @update:open="password = ''" header-title="비밀번호 확인" size="sm">
    <template #header-button>
      <DialogClose as-child>
        <!-- TODO: Lazy open시 Button에 테두리 생기는 이슈 존재. 해결되면 Button 사용하도록 변경할 것 -->
        <div class="h-fit w-fit cursor-pointer rounded-lg p-2 text-slate-500 hover:bg-slate-200 active:bg-slate-300">
          <Icon name="heroicons:x-mark" class="h-6 w-6" />
        </div>
      </DialogClose>
    </template>
    <template #body-inner>
      <FormKit
        v-model="password"
        type="password"
        name="password"
        label="비밀번호"
        outer-class="!mb-0"
        :errors="errorMessages" />
    </template>
    <template #footer-right-button>
      <Button :disabled="!password" size="lg" class="min-w-[6.125rem]" @click="mutate">확인</Button>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { useAPI } from '~/composables/shared/useAPI'
import { usePasswordConfirmModal } from '~/composables/shared/usePasswordConfirmModal'
import { usePasswordConfirmStore } from '~/store/shared/passwordConfirmStore'

const api = useAPI()

const password = ref('')
const errorMessages = ref<string[]>([])
const store = usePasswordConfirmStore()

const { confirm, dialog, close } = usePasswordConfirmModal()

const { mutate } = useMutation({
  mutationFn: async () => {
    return await api.account.user.checkPassword({
      body: {
        password: password.value,
        ...store.authInfo!
      }
    })
  },
  onSuccess(data) {
    store.token = data?.token
    confirm()
    close()
  },
  onError(error) {
    errorMessages.value = error.response._data?.password
  }
})
</script>
